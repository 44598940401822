import React, { useState } from 'react';
import { Container } from 'reactstrap';
import Trailer from '../assets/img/Trailer.m4v';
import Gallery1 from '../assets/img/Compressed/Gallery/1.jpg';
import Gallery2 from '../assets/img/Compressed/Gallery/Brotherhood.jpg';
import Gallery3 from '../assets/img/Compressed/Gallery/Doom.jpg';
import Gallery5 from '../assets/img/Compressed/Gallery/Dragon Tribe.jpg';
import Gallery6 from '../assets/img/Compressed/Gallery/Eternals.jpg';
import Gallery7 from '../assets/img/Compressed/Gallery/Foresters.jpg';
import Gallery8 from '../assets/img/Compressed/Gallery/Heros.jpg';
import Gallery9 from '../assets/img/Compressed/Gallery/2.jpg';
import Gallery10 from '../assets/img/Compressed/Gallery/Sword guard.jpg';
import Gallery11 from '../assets/img/Compressed/Gallery/The forgotten.jpg';
import Gallery12 from '../assets/img/Compressed/Gallery/The Outlanders.jpg';
import Gallery13 from '../assets/img/Compressed/Gallery/Vanguard.jpg';
import Gallery14 from '../assets/img/Compressed/Gallery/Wizards.jpg';
import Glow from '../assets/img/gallery-glow.png';
import Carousel from 'react-spring-3d-carousel';
import { v4 as uuidv4 } from 'uuid';
import { config } from 'react-spring';

function Gallery() {
	const [state, setState] = useState({
		goToSlide: 0,
		offsetRadius: 2,
		showNavigation: true,
		config: { tension: 120, friction: 20 },
	});
	const data = [
		{
			key: uuidv4(),
			content: <img src={Gallery1} alt='1' />,
		},
		{
			key: uuidv4(),
			content: <img src={Gallery2} alt='2' />,
		},
		{
			key: uuidv4(),
			content: <img src={Gallery3} alt='3' />,
		},
		{
			key: uuidv4(),
			content: <img src={Gallery5} alt='5' />,
		},
		{
			key: uuidv4(),
			content: <img src={Gallery6} alt='6' />,
		},
		{
			key: uuidv4(),
			content: <img src={Gallery7} alt='7' />,
		},
		{
			key: uuidv4(),
			content: <img src={Gallery8} alt='8' />,
		},
		{
			key: uuidv4(),
			content: <img src={Gallery9} alt='9' />,
		},
		{
			key: uuidv4(),
			content: <img src={Gallery10} alt='10' />,
		},
		{
			key: uuidv4(),
			content: <img src={Gallery11} alt='11' />,
		},
		{
			key: uuidv4(),
			content: <img src={Gallery12} alt='12' />,
		},
		{
			key: uuidv4(),
			content: <img src={Gallery13} alt='13' />,
		},
		{
			key: uuidv4(),
			content: <img src={Gallery14} alt='14' />,
		},
	].map((slide, index) => {
		return { ...slide, onClick: () => setState({ goToSlide: index }) };
	});

	let xDown = null;
	let yDown = null;

	const getTouches = evt => {
		return (
			evt.touches || evt.originalEvent.touches // browser API
		); // jQuery
	};

	const handleTouchStart = evt => {
		const firstTouch = getTouches(evt)[0];
		xDown = firstTouch.clientX;
		yDown = firstTouch.clientY;
	};

	const handleTouchMove = evt => {
		if (!xDown || !yDown) {
			return;
		}

		let xUp = evt.touches[0].clientX;
		let yUp = evt.touches[0].clientY;

		let xDiff = xDown - xUp;
		let yDiff = yDown - yUp;

		if (Math.abs(xDiff) > Math.abs(yDiff)) {
			/*most significant*/
			if (xDiff > 0) {
				/* left swipe */
				setState({ goToSlide: state.goToSlide + 1 });
			} else {
				/* right swipe */
				setState({ goToSlide: state.goToSlide - 1 });
			}
		} else {
			if (yDiff > 0) {
				/* up swipe */
			} else {
				/* down swipe */
			}
		}
		/* reset values */
		xDown = null;
		yDown = null;
	};

	return (
		<div className='gallery position-relative overflow-hidden'>
			<img
				className='img-fluid'
				src={Glow}
				alt='glow'
				style={{
					position: 'absolute',
					top: '0',
					marginTop: '-80px',
				}}
			/>
			<Container fluid className='mb-5'>
				{/* <h2 className='text-center fs--48 fw--700 text-white'>
					Game Trailer
				</h2>
				<div className='mt-3 text-center'>
					{' '}
					<video
						className='img-fluid'
						src={Trailer}
						autoPlay
						controls
						muted
						loop
					></video>
				</div> */}
				<h2 className='text-center fs--36 fw--700  text-white'>
					GALLERY
				</h2>
				<div
					className='mt-5 custom-carousel'
					onTouchStart={handleTouchStart}
					onTouchMove={handleTouchMove}
				>
					<Carousel
						slides={data}
						goToSlide={state.goToSlide}
						offsetRadius={state.offsetRadius}
						animationConfig={state.config}
						showNavigation={state.showNavigation}
					/>
				</div>
			</Container>
		</div>
	);
}

export default Gallery;
