import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { useRef } from 'react';
import Main from './layout/Main';
import routes from './routes';

function App() {
	const headerRef = useRef(null);
	const aboutRef = useRef(null);
	const buyRef = useRef(null);
	const overViewRef = useRef(null);
	const tokenRef = useRef(null);
	const roadmapRef = useRef(null);
	const headerScroll = () => headerRef.current.scrollIntoView();
	const aboutScroll = () => aboutRef.current.scrollIntoView();
	const buyScroll = () => buyRef.current.scrollIntoView();
	const overviewScroll = () => overViewRef.current.scrollIntoView();
	const tokenScroll = () => tokenRef.current.scrollIntoView();
	const roadmapScroll = () => roadmapRef.current.scrollIntoView();
	return (
		<>
			<BrowserRouter>
				<Switch>
					{routes.map(route => {
						switch (route.layout) {
							case 'main':
								return (
									<Route exact path={route.path}>
										<Main>
											<route.component
												headerRef={headerRef}
												aboutRef={aboutRef}
												buyRef={buyRef}
												overViewRef={overViewRef}
												tokenRef={tokenRef}
												roadmapRef={roadmapRef}
												headerScroll={headerScroll}
												aboutScroll={aboutScroll}
												buyScroll={buyScroll}
												overviewScroll={overviewScroll}
												tokenScroll={tokenScroll}
												roadmapScroll={roadmapScroll}
											/>
										</Main>
									</Route>
								);
						}
					})}
					<Redirect to='/' />
				</Switch>
			</BrowserRouter>
		</>
	);
}

export default App;
