import { createSlice } from '@reduxjs/toolkit'

const initialState = { 
    walletAddress: "", 
}

const web3Slice = createSlice({
  name: 'web3Slice',
  initialState,
  reducers: {
    setWalletAddress(state,action) {
      state.walletAddress = action.payload;
    },
  },
})

export const { setWalletAddress } = web3Slice.actions
export default web3Slice.reducer