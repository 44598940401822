import React from "react";
import Header from "./Header";
import LogoCap from "../assets/img/Logo-cap.png";
import Shadow from "../assets/img/about-shadow.png";
import HeroImg from "../assets/img/HeroImg.jpg";
function Hero({
  aboutScroll,
  buyScroll,
  overviewScroll,
  tokenScroll,
  roadmapScroll,
  headerRef,
}) {
  return (
    <div
      className="hero position-relative"
      style={{ backgroundImage: `url(${HeroImg})` }}
    >
      <img
        src={Shadow}
        alt="shadow"
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
        }}
      />
      <div ref={headerRef}>
        <Header
          aboutScroll={aboutScroll}
          buyScroll={buyScroll}
          overviewScroll={overviewScroll}
          tokenScroll={tokenScroll}
          roadmapScroll={roadmapScroll}
        />
      </div>
      <div className="hero__logo-cap">
        <img src={LogoCap} alt="logo" className="img-fluid" />
        {/* <br />
        <div className="text-center address-container text-white mt-3 p-2">
          <h5>Contract Address</h5>{" "}
          <a
            href="https://bscscan.com/address/0x621645E2F7f119Bd491D08FaA58115DD3792579c"
            target="_blank"
          >
            0x621645E2F7f119Bd491D08FaA58115DD3792579c
          </a>
        </div> */}
      </div>
    </div>
  );
}

export default Hero;
