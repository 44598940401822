import React from "react";
import { Col, Container, Row } from "reactstrap";
import KYC from "../assets/img/KYC.webp";
import Audit from "../assets/img/Audit.webp";
import Glow1 from "../assets/img/roadmap-glow1.png";
import Glow2 from "../assets/img/roadmap-glow2.png";
import Glow3 from "../assets/img/roadmap-glow3.png";

function KYCAudit() {
  return (
    <div className="roadmap mt-8 position-relative">
      <h2 className="fs--36 fw--700 text-white text-center">KYC & AUDIT</h2>

      <Container className="mt-7 position-relative">
        <Row className="align-items-center">
          <Col md="6" className="mt-5 mt-md-0 ">
            <img src={KYC} alt="1" className="img-fluid" />
          </Col>
          <Col md="6" className="mt-5 mt-md-0 ">
            <a
              href="https://app.solidproof.io/projects/world-of-warriors"
              target="_blank"
            >
              <img src={Audit} alt="1" className="img-fluid" />
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default KYCAudit;
