import React, { useState } from 'react';
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink,
	Button,
	Container,
	UncontrolledTooltip,
} from 'reactstrap';
import Logo from '../assets/img/logo.png';
import Wallet from '../assets/img/empty-wallet.png';
import { useSelector } from 'react-redux';
import { connectWallet } from '../web3-helpers/web3-helper';
function Header({
	aboutScroll,
	buyScroll,
	overviewScroll,
	tokenScroll,
	roadmapScroll,
}) {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => setIsOpen(!isOpen);

	const { walletAddress } = useSelector(state => state?.web3Reducer);

	return (
		<Container className='hero__header align-items-center' fluid>
			<Navbar expand='xl'>
				<NavbarBrand href='/'>
					<img src={Logo} alt='logo' />
				</NavbarBrand>
				<NavbarToggler onClick={toggle}>
					<i className='fa fa-bars text-white'></i>
				</NavbarToggler>
				<Collapse isOpen={isOpen} navbar>
					<Nav className='ml-auto' navbar>
						<NavItem>
							<NavLink onClick={aboutScroll}>About</NavLink>
						</NavItem>
						<NavItem>
							<NavLink onClick={buyScroll}>$WOWR</NavLink>
						</NavItem>
						<NavItem>
							<NavLink onClick={overviewScroll}>Overview</NavLink>
						</NavItem>
						<NavItem>
							<NavLink onClick={tokenScroll}>Tokenomincs</NavLink>
						</NavItem>
						<NavItem>
							<NavLink onClick={roadmapScroll}>Roadmap</NavLink>
						</NavItem>
						<NavItem>
							<a
								href='WorldOfWarriors-whitepaper.pdf'
								target='_blank'
							>
								<NavLink>Whitepaper</NavLink>
							</a>
						</NavItem>
						<NavItem className='mr-3' id='stakingTooltip'>
							<NavLink>Staking</NavLink>
						</NavItem>
						<Button
							className='hero__header__connect fs--15 fw--700'
							onClick={() => {
								if (!walletAddress) {
									connectWallet();
								}
							}}
						>
							<img src={Wallet} alt='wallet' className='mr-3' />
							{walletAddress
								? walletAddress?.replace(
										walletAddress?.slice(5, 36),
										'...'
								  )
								: 'Connect Wallet'}
						</Button>
					</Nav>
				</Collapse>
			</Navbar>
			<UncontrolledTooltip placement='bottom' target='stakingTooltip'>
				Coming Soon
			</UncontrolledTooltip>
		</Container>
	);
}

export default Header;
