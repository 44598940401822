import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { configEnv } from './configEnv';
import { web3Store } from '../store/store';
import { setWalletAddress } from '../store/web3Slice';

export let web3 = new Web3(configEnv.BSC_RPC);
export const connectWallet = async () => {
	try {
		const state = web3Store.getState();
		const address = state?.web3Reducer?.userAddress;
		if (!address) {
			console.log('Connecting to wallet');
			// /*
			const providerOptions = {
				walletconnect: {
					package: WalletConnectProvider,
					options: {
						bridge: 'https://bridge.walletconnect.org',
						chainId: configEnv.BSC_CHAINID, //137 for mainnet
						// network: configEnv.BSC_NETWORK_ID, //matic for mainnet
						rpc: {
							// 43113: configEnv.BSC_RPC,
							56:configEnv.BSC_RPC,
						},
					},
				},
			};

			console.log('Provider is', providerOptions);

			const web3Modal = new Web3Modal({
				// network: configEnv.BSC_NETWORK_ID,
				cacheProvider: false, // optional
				providerOptions, // required
				disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
			});

			console.log('Web3Modal instance is', web3Modal);
			const provider = await web3Modal.connect();

			provider.on('accountsChanged', async accounts => {
				console.log('Accounts', accounts);
				web3Store.dispatch(setWalletAddress(accounts[0]));
				// await getUserData();
			});

			web3 = new Web3(provider);

			console.log('Web3 instance is', web3);

			const chainid = await web3.eth.getChainId();

			console.log(chainid);
			if (chainid !== configEnv.BSC_CHAINID) {
				// notification("error", `Please connect to ${configEnv.BSC_NETWORK_NAME}`);
                alert(`Please connect to ${configEnv.BSC_NETWORK_NAME}`)
				return;
			}
			const accounts = await web3.eth.getAccounts();
			console.log('Acount is', accounts[0]);

			web3Store.dispatch(setWalletAddress(accounts[0]));
			// await getUserData();
		} else {
			console.log('Already connected');
		}
	} catch (err) {
		console.log(err);
		// notification('error',err.message);
	}
};

export const getContractInstance = async()=>{
    try{
        const contract = new web3.eth.Contract(configEnv?.BSC_CONTRACT_ABI,configEnv?.CONTRACT_ADDRESS);

        return contract;
    }
    catch(err){
        console.log(err);
        throw new Error(err?.message);
    }
}

export const buyToken = async (value)=>{
    try{
        const state = web3Store.getState();
        const valueInWei = web3.utils.toWei(value?.toString(),'ether');
        const contract = await  getContractInstance();
        
        const trx = await contract.methods.buyTokens(state?.web3Reducer?.walletAddress).send({
            from:state?.web3Reducer?.walletAddress,
            value:valueInWei,
        });

        console.log(trx);
        
        return trx;
    }
    catch(err){
        console.log(err);
        throw new Error(err?.message);
    }
}

export const addTokenToMetamask = async()=>{
	try{
		const wasAdded = await web3.currentProvider.request({
			method: 'wallet_watchAsset',
			params: {
			  type: 'ERC20', // Initially only supports ERC20, but eventually more!
			  options: {
				address: configEnv?.TOKEN_ADDRESS, // The address that the token is at.
				symbol: "WOWR", // A ticker symbol or shorthand, up to 5 chars.
				decimals: 18, // The number of decimals in the token
			  },
			},
		  });

		  console.log(wasAdded)
	}
	catch(err){
		console.log(err);
		throw new Error(err?.message);
	}
}