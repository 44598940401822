import Landing from '../views/Landing';

let routes = [
	{
		path: '/',
		component: Landing,
		layout: 'main',
	},
];
export default routes;
