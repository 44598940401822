import React, { useState } from 'react';
import { Button, Container, Input, Label } from 'reactstrap';
import LeftGlow from '../assets/img/left-glow.png';
import BuyBG from '../assets/img/buy-bg.jpg';
import { addTokenToMetamask, buyToken } from '../web3-helpers/web3-helper';
function BuyCard() {
	const [value, setValue] = useState(0);
	const [loading, setLoading] = useState(false);

	const buyHandler = async () => {
		try {
			setLoading(true);

			await buyToken(value);

			setLoading(false);
		} catch (err) {
			setLoading(false);
			console.log(err);
			throw new Error(err);
		}
	};

	return (
		<div className='position-relative'>
			<Container
				fluid
				className='buy mt-8 text-white'
				style={{ backgroundImage: `url(${BuyBG})` }}
			>
				<div className='buy__card mx-auto'>
					<h2 className='fs--18 fw--700 text-center'>
						Presale round 2 , $0.002 per $WOWR tokens{' '}
					</h2>
					<div className='mt-5 text-center'>
						<Label className='fs--12 fw--700'>
							Enter the amount in BNB. The minimum buy is 0.1 BNB
							and the Maximum is 6 BNB
						</Label>
						<div className='buy__input-container text-left'>
							<Label className='fs--16 fw--700'>Amount</Label>
							<Input
								type='number'
								placeholder='3.0786678990'
								className='mt--2 amount_input'
								min={0}
								value={value}
								onChange={e => setValue(e?.target?.value)}
							/>
						</div>
						<Label className='fs--12 fw--700 mt-2'>
							*Launch price will be $0.01 per WOWR token on
							Pancake
						</Label>
					</div>
					<div className='mt-4'>
						<Button
							block
							className='buy__btn pointer'
							onClick={buyHandler}
						>
							{loading ? (
								<div
									class='spinner-border '
									role='status'
									style={{
										width: '1rem',
										height: '1rem',
										color: 'black',
									}}
								>
									<span class='sr-only'>Loading...</span>
								</div>
							) : (
								<>BUY</>
							)}
						</Button>
					</div>
					<h2
						className='text-center fs--12 fw--700 mt-3 pointer add_to_metamask'
						onClick={addTokenToMetamask}
					>
						Add World of Warriors $WOWR token to Metamask
					</h2>
				</div>
			</Container>
			<img
				src={LeftGlow}
				alt='glow'
				className='img-fluid d-none d-sm-block'
				style={{
					position: 'absolute',
					top: '0',
					marginTop: '60px',
					zIndex:-1,
				}}
			/>
		</div>
	);
}

export default BuyCard;
