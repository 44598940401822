import React from "react";
import { Button, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import Logo from "../assets/img/Logo-cap.png";
import fb from "../assets/img/fb.png";
import twitter from "../assets/img/twitter.png";
import insta from "../assets/img/insta.png";

function Footer({
  headerScroll,
  aboutScroll,
  buyScroll,
  overviewScroll,
  tokenScroll,
  roadmapScroll,
}) {
  return (
    <div className="footer mt-8">
      <Container>
        <Row className="align-items-center">
          <Col md="4" className="text-center text-md-left">
            <img
              src={Logo}
              alt="logo"
              height="120px"
              style={{ cursor: "pointer" }}
            />
          </Col>
          <Col className="text-center mt-5 mt-md-0" md="4">
            <div className="d-flex justify-content-center">
              <a
                href="https://www.facebook.com/worldofwarriors.nft"
                target="_blank"
                rel="noreferrer"
              >
                <Button className="mr-3 footer__social">
                  <img src={fb} alt="fb" />
                </Button>
              </a>
              <a
                href="https://twitter.com/World0fWarriors"
                target="_blank"
                rel="noreferrer"
              >
                <Button className="mr-3 footer__social">
                  <img src={twitter} alt="twitter" />
                </Button>
              </a>
              <a
                href="https://www.instagram.com/worldofwarriors_nft/"
                target="_blank"
                rel="noreferrer"
              >
                <Button className="mr-3 footer__social">
                  <img src={insta} alt="instagram" />
                </Button>
              </a>
              <a
                href="https://t.me/worldofwarriors_nft"
                target="_blank"
                rel="noreferrer"
              >
                <Button className="footer__social">
                  <i className="fab fa-telegram-plane ml--1 mt-2"></i>
                </Button>
              </a>
            </div>
            <h2 className="mt-3 mb-0 text-muted fs--14 fw--400">
              © {new Date().getFullYear()} World of Warriors [Storm Games]. All
              rights reserved.
            </h2>
          </Col>
          <Col md="4" className="mt-5 mt-md-0">
            <div className="d-flex justify-content-center">
              <div className="fs--14 fw--700 text-white mr-5">
                <p className="mb-2 pointer" onClick={aboutScroll}>
                  ABOUT
                </p>
                <p className="mb-2 pointer" onClick={buyScroll}>
                  $WOWR
                </p>
                <p className="mb-2 pointer" onClick={overviewScroll}>
                  OVERVIEW
                </p>
                <p className="mb-0 pointer" onClick={tokenScroll}>
                  TOKENOMICS
                </p>
              </div>
              <div className="fs--14 fw--700 text-white">
                <a href="WorldOfWarriors-whitepaper.pdf" target="_blank">
                  <p className="mb-2">WHITEPAPER</p>
                </a>

                <p className="mb-2 pointer" onClick={roadmapScroll}>
                  ROADMAP
                </p>
                <p className="mb-0 pointer" id="stakingTooltip">
                  STAKING
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <UncontrolledTooltip placement="bottom" target="stakingTooltip">
          Coming Soon
        </UncontrolledTooltip>
      </Container>
    </div>
  );
}

export default Footer;
