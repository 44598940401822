import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';

// Import CSS here
import './assets/scss/index.scss';
import { web3Store } from './store/store';

ReactDOM.render(
	<React.StrictMode>
		<Provider store={web3Store}>
			<App />
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);
