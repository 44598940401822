import About from "../components/About";
import BuyCard from "../components/BuyCard";
import Footer from "../components/Footer";
import Gallery from "../components/Gallery";
import GameOverview from "../components/GameOverview";
import Hero from "../components/Hero";
import KYCAudit from "../components/KYCandAudit";
import Roadmap from "../components/Roadmap";
import Tokenomics from "../components/Tokenomics";

const Landing = ({
  headerRef,
  aboutRef,
  buyRef,
  overViewRef,
  tokenRef,
  roadmapRef,
  headerScroll,
  aboutScroll,
  buyScroll,
  overviewScroll,
  tokenScroll,
  roadmapScroll,
}) => {
  return (
    <>
      <Hero
        headerRef={headerRef}
        aboutScroll={aboutScroll}
        buyScroll={buyScroll}
        overviewScroll={overviewScroll}
        tokenScroll={tokenScroll}
        roadmapScroll={roadmapScroll}
      />
      <div ref={aboutRef}>
        {" "}
        <About />
      </div>
      <div ref={buyRef}>
        <BuyCard />
      </div>
      <div ref={overViewRef}>
        <GameOverview />
      </div>
      <div ref={tokenRef}>
        <Tokenomics />
      </div>

      <Gallery />
      <div ref={roadmapRef}>
        <Roadmap />
      </div>
      <KYCAudit />
      <Footer
        headerScroll={headerScroll}
        aboutScroll={aboutScroll}
        buyScroll={buyScroll}
        overviewScroll={overviewScroll}
        tokenScroll={tokenScroll}
        roadmapScroll={roadmapScroll}
      />
    </>
  );
};

export default Landing;
