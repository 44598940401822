import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import TokenomicImg from '../assets/img/tokenomics-hero.png';
import Shadow from '../assets/img/about-shadow.png';
function Tokenomics() {
	return (
		<div className='tokenomics pb-10 pt-6 position-relative'>
			<h2 className='text-center text-white fs--36 fw--700 tokenomics__title'>
				Tokenomics
			</h2>
			<h4 className='text-center text-white mt-2 fs--20 fw--700'>
				TOTAL SUPPLY: 1,000,000,000
			</h4>
			<img
				src={Shadow}
				alt='shadow'
				style={{
					position: 'absolute',
					width: '100%',
					top: '0',
					marginTop: '-10px',
					transform: 'rotate(180deg)',
				}}
			/>
			<img
				src={Shadow}
				alt='shadow'
				style={{
					position: 'absolute',
					width: '100%',
					bottom: '0',
				}}
			/>
			<Container fluid>
				<div className='mt-6 tokenomics__container position-relative'>
					<Row className='align-items-center'>
						<Col md='5'>
							<img
								src={TokenomicImg}
								alt='img'
								className='img-fluid'
							/>
						</Col>
						<Col md='7'>
							<div className='tokenomics__container__table'>
								<div className='tokenomics__container__table-head'>
									<h2 className='fs--12 fw--700'>
										Token Breakdown{' '}
									</h2>
									<h2 className='fs--12 fw--700 '>
										Number Of Tokens
									</h2>
									<h2 className='fs--12 fw--700 '>
										% Tokens{' '}
									</h2>
									<h2 className='fs--12 fw--700 '>
										Vesting{' '}
									</h2>
								</div>
								<hr style={{ background: 'white' }} />
								<div className='tokenomics__container__table-body'>
									<div className='tokenomics__container__table-body__row'>
										<h5 className='mb-0 fs--13 fw--700'>
											Game Development/ <br /> Ecosystem
											Growth{' '}
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '>
											90,000,000
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '>
											9%{' '}
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '>
											4 years Linear Distribution{' '}
										</h5>
									</div>
									<div className='tokenomics__container__table-body__row mt-2'>
										<h5 className='mb-0 fs--13 fw--700'>
											Gaming Rewards
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '>
											250,000,000
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '>
											25%{' '}
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '></h5>
									</div>
									<div className='tokenomics__container__table-body__row mt-2'>
										<h5 className='mb-0 fs--13 fw--700'>
											Staking Rewards
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '>
											150,000,000
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '>
											15%{' '}
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '>
											60 Months Linear Distribution
										</h5>
									</div>
									<div className='tokenomics__container__table-body__row mt-2'>
										<h5 className='mb-0 fs--13 fw--700'>
											Presale
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '>
											90,000,000
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '>
											9%{' '}
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '>
											1 Month lockup
										</h5>
									</div>
									<div className='tokenomics__container__table-body__row mt-2'>
										<h5 className='mb-0 fs--13 fw--700'>
											Public Sale
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '>
											120,000,000
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '>
											12%{' '}
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '></h5>
									</div>
									<div className='tokenomics__container__table-body__row mt-2'>
										<h5 className='mb-0 fs--13 fw--700'>
											Liquidity
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '>
											70,000,000
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '>
											7%{' '}
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '></h5>
									</div>
									<div className='tokenomics__container__table-body__row mt-2'>
										<h5 className='mb-0 fs--13 fw--700'>
											Advisors
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '>
											30,000,000{' '}
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '>
											3%{' '}
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '>
											3 Months Lockup, 12 Months Linear
											Vest
										</h5>
									</div>
									<div className='tokenomics__container__table-body__row mt-2'>
										<h5 className='mb-0 fs--13 fw--700'>
											Team
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '>
											100,000,000{' '}
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '>
											10%{' '}
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '>
											3 Months Lockup, 12 Months Linear
											Vest
										</h5>
									</div>
									<div className='tokenomics__container__table-body__row mt-2'>
										<h5 className='mb-0 fs--13 fw--700'>
											Reserve Funds
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '>
											100,000,000{' '}
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '>
											10%{' '}
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '></h5>
									</div>
									<div className='tokenomics__container__table-body__row mt-2'>
										<h5 className='mb-0 fs--13 fw--700'>
											Total{' '}
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '>
											1,000,000,000{' '}
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '>
											100%{' '}
										</h5>
										<h5 className='mb-0 fs--13 fw--700 '></h5>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</Container>
		</div>
	);
}

export default Tokenomics;
