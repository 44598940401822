import React from 'react';
import { Container, Row, Col, Card, CardImg, CardBody } from 'reactstrap';
import Img1 from '../assets/img/overview1.jpg';
import Img2 from '../assets/img/overview2.jpg';
import Img3 from '../assets/img/overview3.jpg';
import Img4 from '../assets/img/Compressed/Compressed/comp.jpg';
import RightGlow from '../assets/img/right-glow.png';
function GameOverview() {
	return (
		<div className='position-relative'>
			<Container className='game-overview mt-8 mb-8'>
				<h2 className='text-center text-white fs--36 fw--700'>
					GAME OVERVIEW
				</h2>
				<Row className='mt-5'>
					<Col md='6'>
						<Card className='h-100'>
							<CardImg src={Img1} alt='overview1' top />
							<CardBody className='p-0 text-white'>
								<h2 className='mt-3 fs--24 fw--700'>
									Collect Powerful Warriors
								</h2>
								<p className='fs--16 fw--500 mt-3'>
									Collect from hundreds of powerful warriors
									with unique features from ten factions and
									fall into three categories Attacker –
									Defender - Supporter and further these
									warriors fall under different tiers of units
									ranging from <strong>Common</strong>, Rare,
									Elite, Epic, and Legendary.
								</p>
							</CardBody>
						</Card>
					</Col>
					<Col md='6' className='mt-5 mt-md-0'>
						<Card className='h-100'>
							<CardImg src={Img2} alt='overview2' top />
							<CardBody className='p-0 text-white'>
								<h2 className='mt-3 fs--24 fw--700'>
									Enhance Warriors and their skills
								</h2>
								<p className='fs--16 fw--500 mt-3'>
									Collect warriors with unique skills and
									freely decide how to train them by choosing
									the right equipment, Artifacts, and weapons
									in the form of NFT collectible traits.
									Upgrade their skills and get your power
									enhanced to lead them to invincibility on
									the battlefields of PVP and PVE!
								</p>
							</CardBody>
						</Card>
					</Col>
					<Col md='6' className='mt-5'>
						<Card className='h-100'>
							<CardImg src={Img3} alt='overview3' top />
							<CardBody className='p-0 text-white'>
								<h2 className='mt-3 fs--24 fw--700'>
									Play to Earn
								</h2>
								<p className='fs--16 fw--500 mt-3'>
									World of Warriors is designed where players
									can generate money from their gaming
									experience. Players Earn $WOWR token if they
									complete any single quest or engage in
									battles against the Monsters of the Game or
									fight in the PvP Arena. Further players can
									craft valuable NFT assets or find treasure
									chests of enormous value, which can be sold
									or traded on the marketplace for $WOWR – the
									valuable token launched with the game.
								</p>
							</CardBody>
						</Card>
					</Col>
					<Col md='6' className='mt-5'>
						<Card className='h-100'>
							<CardImg src={Img4} alt='overview4' top />
							<CardBody className='p-0 text-white mt-3'>
								<h2 className='mt-3 fs--24 fw--700'>
									Gameplay
								</h2>
								<p className='fs--16 fw--500 mt-3'>
									Lead your warriors on the battlefields of
									PvE and PvP(5vs5 Battle), Explore the
									Metaverse with 12 continents of the game
									with immersive sceneries such as Snowfield,
									Deserts, Forests, etc… Explore the Rich
									Dungeons challenge powerful bosses, and
									collect rare materials. Compete with players
									from all over the world, Fight for the
									highest honour and get rich rewards!
								</p>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			<img
				src={RightGlow}
				alt='glow'
				className='img-fluid'
				style={{
					position: 'absolute',
					bottom: '0',
					right: '0',
					marginBottom: '-70px',
				}}
			/>
		</div>
	);
}

export default GameOverview;
