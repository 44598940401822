import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Roadmap1 from '../assets/img/roadmap1.png';
import Roadmap2 from '../assets/img/roadmap2.png';
import Roadmap3 from '../assets/img/roadmap3.png';
import Glow1 from '../assets/img/roadmap-glow1.png';
import Glow2 from '../assets/img/roadmap-glow2.png';
import Glow3 from '../assets/img/roadmap-glow3.png';

function Roadmap() {
	return (
		<div className='roadmap mt-8 position-relative'>
			<h2 className='fs--36 fw--700 text-white text-center'>ROADMAP</h2>
			<img
				className='img-fluid'
				src={Glow1}
				alt='glow'
				style={{
					position: 'absolute',
					top: '0',
					left: '0',
				}}
			/>
			<img
				src={Glow2}
				alt='glow'
				className='img-fluid'
				style={{
					position: 'absolute',
					top: '0',
					right: '0',
					marginTop: '80px',
				}}
			/>
			<img
				src={Glow3}
				alt='glow'
				className='img-fluid'
				style={{
					position: 'absolute',
					top: '40%',
					left: '0',
					marginTop: '80px',
				}}
			/>
			<Container className='mt-8 position-relative'>
				<Row className='align-items-center'>
					<Col md='6'>
						<div className='roadmap__card text-white'>
							<h2 className='fs--24 fw--600'>2022 Q3</h2>
							<ul className='mb-0 fs--14 fw--500'>
								<li>Token Smart Contracts</li>
								<li>Game Development</li>
								<li>Initial Characters Sneak Peek</li>
								<li>Game Contracts, NFT Contracts, Etc…</li>
								<li> Tokenomics</li>
							</ul>
						</div>
						<div className='roadmap__card text-white mt-3'>
							<h2 className='fs--24 fw--600'>2022 Q4 </h2>
							<ul className='mb-0 fs--14 fw--500'>
								<li>Token Release </li>
								<li>Private / Presale Rounds</li>
								<li>Listing On Coinmarketcap, Coingecko</li>
								<li>
									{' '}
									Marketing, Advertising Game Trailer Release
								</li>
								<li> Character Introductions</li>
							</ul>
						</div>
					</Col>
					<Col md='6' className='mt-5 mt-md-0 d-none d-md-block'>
						<img src={Roadmap1} alt='1' className='img-fluid' />
					</Col>
					<Col md='6' className='mt-5 d-none d-md-block'>
						{' '}
						<img src={Roadmap2} alt='2' className='img-fluid' />
					</Col>
					<Col md='6' className='mt-5'>
						<div className='roadmap__card text-white'>
							<h2 className='fs--24 fw--600'>2023 Q1 </h2>
							<ul className='mb-0 fs--14 fw--500'>
								<li> Private Beta Launch Of The Game</li>
								<li>NFTs Of The Warriors Released</li>
								<li> Establishing Key Partnerships </li>
								<li> Getting Advisors On-Board</li>
								<li> Staking Launch</li>
								<li> Game Development Updates</li>
							</ul>
						</div>
						<div className='roadmap__card text-white mt-3'>
							<h2 className='fs--24 fw--600'>2023 Q2 </h2>
							<ul className='mb-0 fs--14 fw--500'>
								<li>NFT Marketplace Launch </li>
								<li>Public Beta Launch</li>
								<li>Expanding The World </li>
								<li> Guild System Development</li>
								<li> Game Developments Updates</li>
							</ul>
						</div>
					</Col>
					<Col md='6' className='mt-5'>
						<div className='roadmap__card text-white'>
							<h2 className='fs--24 fw--600'>2023 Q3 </h2>
							<ul className='mb-0 fs--14 fw--500'>
								<li>Full Game Launch</li>
								<li>Economy System In Place</li>
								<li>Live Game Rewards</li>
								<li>First PvP Arena Tournament</li>
							</ul>
						</div>
						<div className='roadmap__card text-white mt-3'>
							<h2 className='fs--24 fw--600'>2023 Q4 </h2>
							<ul className='mb-0 fs--14 fw--500'>
								<li>Coming Soon....</li>
							</ul>
						</div>
					</Col>
					<Col md='6' className='mt-5 d-none d-md-block'>
						{' '}
						<img src={Roadmap3} alt='3' className='img-fluid' />
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default Roadmap;
