import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import AboutHero from '../assets/img/about-hero.png';
import AboutFooter from '../assets/img/about-footer.png';
import Shadow from '../assets/img/about-shadow.png';
function About() {
	return (
		<div className='about position-relative'>
			<img
				src={Shadow}
				alt='shadow'
				style={{
					position: 'absolute',
					top: 0,
					marginTop: '-10px',
					width: '100%',
					transform: 'rotate(180deg)',
				}}
			/>
			<img
				src={Shadow}
				alt='shadow'
				style={{
					position: 'absolute',
					bottom: 0,
					marginBottom: '-15px',
					width: '100%',
					zIndex: '1000',
				}}
			/>
			<h2 className='text-center text-white fs--36 fw--700 about__title'>
				ABOUT
			</h2>
			<Container className='mt-5'>
				<Row className='align-items-center'>
					<Col md='6' className='d-none d-md-block'>
						<img src={AboutHero} alt='hero' className='img-fluid' />
					</Col>
					<Col md='6'>
						<div className='about__text-container'>
							<p className='mb-0 text-color fs--20 fw--700'>
								World of Warriors is an RPG Game based on NFT
								Play-to-Earn model developed on Blockchain
								technology and Binance Network.
								<br /> <br />
								Players collect powerful NFT-Warriors, summon
								the strongest companions, explore rich dungeons
								and kill monsters to earn rewards, or battle
								with other players… We proudly introduce the
								token named $WOWR which works to empower players
								to immerse themselves in the game and earn while
								they play!
							</p>
						</div>
					</Col>
				</Row>
			</Container>
			{/* <img
				src={AboutFooter}
				alt='footer'
				className='img-fluid about__footer-img'
			/> */}
		</div>
	);
}

export default About;
